import { createRouter, createWebHashHistory } from 'vue-router';
import { TRouter } from '@/types/router';
import { getUserInfo } from '@/utils/auth';

// 路由拆解：自动引入每个单页的路由 .src/page/*/router.ts
const moduleRoutes = [];
const modules = import.meta.glob('@/pages/*/router.ts', { eager: true }); // 只获取首级路由

for (const path in modules) {
  // @ts-ignore
  moduleRoutes.push(modules[path].default);
}

export const routes: TRouter[] = [{ path: '/', redirect: '/tts/creation' }, ...moduleRoutes].flat();

export const router = createRouter({
  history: createWebHashHistory(),
  routes
});

/**
 * 路由守卫
 */
router.beforeEach((to, from, next) => {
  if (to.meta?.requireAuth) {
    getUserInfo().then((isLogin: boolean) => {
      if (!isLogin) {
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        });
      } else {
        next();
      }
    });
  } else {
    next();
  }
});
