/*
 * @Author: huhaibiao
 * @Date: 2023-04-21 22:11:20
 */
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Antd from 'ant-design-vue';
import { router } from './router';
import request from '@/api/request';
import i18n from '@/i18n';

import App from './App.vue';

import 'vue-cropper/dist/index.css';
import '@/assets/less/common.less';
import 'virtual:svg-icons-register';

// 构建vue实例
const app = createApp(App);

const pinia = createPinia();

app.use(router).use(pinia).use(Antd).use(i18n);

// 初始化请求中心
request.initInterceptors();

app.mount('#app');
