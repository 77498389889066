import { TRouter } from '@/types/router';

export default {
  path: '/user',
  name: 'User',
  component: () => import('./index.vue'),
  redirect: '/user/profile',
  meta: {
    requireAuth: true
  },
  children: [
    {
      path: '/user/profile',
      name: 'Profile',
      component: () => import('./profile.vue')
    }
  ]
} as TRouter;
