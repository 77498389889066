import { TRouter } from '@/types/router';
import { BellOutlined } from '@ant-design/icons-vue';

export default {
  path: '/notification',
  name: 'Notification',
  component: () => import('./index.vue'),
  meta: {
    requireAuth: true,
    icon: BellOutlined,
    title: 'router.notification'
  }
} as TRouter;
