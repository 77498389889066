import { TRouter } from '@/types/router';
import { AudioOutlined, UnorderedListOutlined } from '@ant-design/icons-vue';
import TtsOutlined from '@/components/icon/TtsOutlined.vue';

export default {
  path: '/tts',
  name: 'TTS',
  component: () => import('@/pages/tts/index.vue'),
  redirect: '/tts/creation',
  meta: {
    sort: 20,
    icon: TtsOutlined,
    navigation: 'router.tts',
    requireAuth: true
  },
  beforeEnter: async () => {
    /**
     * 这里定义控制台单页应用进入前的导航守卫逻辑
     */
  },
  children: [
    {
      path: '/tts/creation',
      name: 'TTSCreation',
      component: () => import('@/pages/tts/creation.vue'),
      meta: {
        icon: AudioOutlined,
        navigation: 'router.creationVoice',
        title: 'router.creationVoice'
      }
    },
    {
      path: '/tts/voiceList',
      name: 'TTSVoiceList',
      component: () => import('@/pages/tts/voiceList.vue'),
      meta: {
        icon: UnorderedListOutlined,
        navigation: 'router.voiceList',
        title: 'voice.myVoices'
      }
    }
  ]
} as TRouter;
