import request from '@/api/request';
import { IRequestParams } from '@/types/request';

const prefix = 'v2';

export default {
  /**
   * 提交用户反馈
   */
  submitFeedback: (options?: IRequestParams<{ content: string }>) =>
    request.axiosInstance({
      url: `/${prefix}/feedback`,
      method: 'POST',
      desc: '提交用户反馈',
      ...options
    })
};
