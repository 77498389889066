import { createI18n } from 'vue-i18n';
import en from '@/locales/en.json';
import zh from '@/locales/zh.json';

const i18n = createI18n({
  legacy: false, // you must specify 'legacy: false' option
  globalInjection: true,
  fallbackLocale: 'en', // 设置备用语言
  locale: localStorage.getItem('lang') || navigator.language || 'en', // default locale
  messages: {
    en,
    zh
  }
});

export default i18n;
