<template>
  <svg aria-hidden="true" :fill="props.color" :width="props.size" :height="props.size" v-bind="props.svgAttributes">
    <use :href="symbolId" />
  </svg>
</template>

<script setup lang="tsx" name="SvgIcon">
import type { SVGAttributes } from 'vue';

interface ISvgIcon {
  prefix?: string;
  name: string;
  size?: number | string;
  color?: string;
  svgAttributes?: SVGAttributes;
}

const props = withDefaults(defineProps<ISvgIcon>(), {
  prefix: 'icon',
  name: '',
  size: 16,
  color: '',
  svgAttributes: () => ({})
});

const symbolId = computed(() => `#${props.prefix}-${props.name}`);
</script>
