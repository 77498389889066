<template>
  <a-layout class="app-container">
    <Aside :items="items" />
    <a-layout-content
      class="app-content"
      :style="{ ...props.contentStyles, marginLeft: useEvent.event.affix ? '70px' : 0 }"
    >
      <p class="content-body-title" v-if="router.currentRoute.value.meta?.title">
        {{ t(title) }}
      </p>
      <slot />
    </a-layout-content>
  </a-layout>
</template>

<script lang="tsx" setup name="Layout">
import { MenuOutlined } from '@ant-design/icons-vue';
import i18n from '@/i18n';
import { routes } from '@/router';

import Aside from './Aside.vue';

import type { CSSProperties } from 'vue';
import type { ItemType } from 'ant-design-vue';
import type { TRouter } from '@/types/router';
import { useRouter } from 'vue-router';
import { eventStore } from '@/store/event';

interface IProps {
  contentStyles?: CSSProperties;
}

const router = useRouter();
const useEvent = eventStore();
const { t } = i18n.global;
const props = withDefaults(defineProps<IProps>(), {
  contentStyles: () => ({})
});
const items = ref(
  routes
    .filter(child => child.meta?.navigation)
    .sort((a, b) => (a.meta?.sort || 0) - (b.meta?.sort || 0))
    .map(item => processNode(item))
);
const title = ref<string>(router.currentRoute.value.meta?.title as string);

watch(
  () => useEvent.event.lang,
  val => {
    i18n.global.locale.value = val;
    localStorage.setItem('lang', val);
    items.value = routes
      .filter(child => child.meta?.navigation)
      .sort((a, b) => (a.meta?.sort || 0) - (b.meta?.sort || 0))
      .map(item => processNode(item));
  }
);

/**
 * 菜单格式化
 * @param node
 */
function processNode(node: TRouter): ItemType {
  let children: ItemType[] = [];

  // 递归处理子节点
  if (node.children && node.children?.length > 0) {
    children = node.children.map(child => processNode(child)).filter(item => !!item);
  }

  return node.meta?.navigation
    ? {
        key: node.path,
        icon: h(node.meta?.icon || MenuOutlined),
        label: i18n.global.t(node.meta?.navigation || ''),
        title: i18n.global.t(node.meta?.navigation || ''),
        children: children.length ? children : undefined
      }
    : null;
}
</script>

<style lang="less" scoped>
.app-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: var(--light-background);

  .app-content {
    padding: 20px;
    position: relative;
    flex: auto;
    height: 100%;
    overflow: auto;

    .content-body-title {
      margin-bottom: 20px;
      line-height: 30px;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
</style>
