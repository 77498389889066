import Contact from './index.vue';
import type { TRouter } from '@/types/router';

export default {
  path: '/contact',
  name: 'Contact',
  component: Contact,
  meta: {
    title: 'router.feedback',
    requireAuth: true
  }
} as TRouter;
