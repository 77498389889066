import { TRouter } from '@/types/router';

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/login/login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/pages/login/register.vue')
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    component: () => import('@/pages/login/reset.vue')
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () => import('@/pages/login/verify.vue')
  },
  {
    path: '/loginTransfer',
    name: 'LoginTransfer',
    component: () => import('@/pages/login/loginTransfer.vue')
  }
] as TRouter[];
