import { userStore } from '@/store/user';
import user from '@/api/user';

/**
 * 获取用户信息
 */
export const getUserInfo = (callback?: () => void): Promise<boolean> => {
  const useUser = userStore();
  if (useUser.user.email) return Promise.resolve(true);

  return new Promise(resolve => {
    user
      .getUserInfo()
      .then(data => {
        useUser.setUser(data);
        useUser.setIsLogin(true);

        callback && callback();
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });
  });
};
