export const theme = {
  token: {
    colorPrimary: '#de592a',
    colorInfo: '#2e69a0',
    colorSuccess: '#50894d',
    colorError: '#a52626',
    colorSuccessBg: '#e6ede5',
    colorErrorBg: '#f1e5e5',
    wireframe: true,
    colorTextBase: '#0d062d'
  }
};
