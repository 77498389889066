<template>
  <a-layout-sider
    v-model:collapsed="collapsed"
    :width="250"
    :collapsedWidth="70"
    collapsible
    theme="light"
    class="aside-style"
    :style="{ position: useEvent.event.affix ? 'absolute' : '' }"
    :trigger="null"
    @mouseenter="switchMenu(false)"
    @mouseleave="switchMenu(true)"
  >
    <section class="aside-header">
      <div class="header-logo" :style="{ width: collapsed ? '24px' : '150px' }">
        <SvgIcon name="logo" :svgAttributes="{ width: 76, height: 19 }" />
      </div>

      <a-button
        type="text"
        size="small"
        v-show="!collapsed"
        :style="{ color: useEvent.event.affix ? '' : '#de592a' }"
        @click="switchAffix(!useEvent.event.affix)"
      >
        <SvgIcon name="collapse" />
      </a-button>
    </section>

    <a-menu
      class="menu-style"
      :items="props.items"
      mode="inline"
      :open-keys="router.currentRoute.value.matched.map(r => r.path)"
      :selectedKeys="[router.currentRoute.value.path]"
      @click="
        item => {
          router.push(item.key);
        }
      "
    />

    <AsideBottom />
  </a-layout-sider>
</template>

<script setup lang="tsx" name="LayoutAside">
import { useRouter } from 'vue-router';
import { eventStore } from '@/store/event';
import i18n from '@/i18n';

import SvgIcon from '@/components/icon/SvgIcon.vue';
import AsideBottom from './AsideBottom.vue';

import type { ItemType } from 'ant-design-vue';
import type { TRouter } from '@/types/router';
import { getUserInfo } from '@/utils/auth';

interface IProps {
  items: ItemType[];
  menu?: Array<TRouter>;
  defaultExpanded?: string[];
}

const props = withDefaults(defineProps<IProps>(), {
  items: () => [],
  menu: () => [],
  defaultExpanded: () => []
});
const { t } = i18n.global;
const useEvent = eventStore();
const router = useRouter();
const collapsed = ref<boolean>(useEvent.event.affix);

onMounted(() => {
  getUserInfo();
});

const switchMenu = (flag: boolean) => {
  if (flag) collapsed.value = useEvent.event.affix && flag;
  else collapsed.value = flag;
};

const switchAffix = (flag: boolean) => {
  useEvent.setItem({ affix: flag });
  collapsed.value = flag;
};
</script>

<style lang="less" scoped>
.aside-style {
  top: 0;
  left: 0;
  padding: 10px;
  height: 100%;
  flex-shrink: 0;
  border-right: 1px solid #dbdbdb;
  z-index: 100;

  .aside-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px;
    width: 100%;
    height: 45px;

    .header-logo {
      margin: 0 auto;
      overflow: hidden;
      transition: width 0.2s;
    }

    .aside-collapse {
      width: 30px;
      height: 30px;
      color: #eb8052;
      border-color: #f7a77e;
    }
  }

  .menu-style {
    height: calc(100% - 240px);
    overflow: auto;
    border-right: none;

    :global(.ant-menu-submenu-popup) {
      display: none;
    }
  }
}
</style>
