import { defineStore } from 'pinia';
import { keyIn } from '@/utils/variable';
import { TUser } from '@/types/user';

// 用户store
export const userStore = defineStore('userStore', () => {
  const user = reactive<TUser>({
    display_name: '',
    avatar_path: '',
    email: '',
    id: 0,
    org_id: 0,
    source: null,
    status: 'pending',
    user_type: ''
  });
  const isLogin = ref(false);

  const setUser = (info: TUser) => {
    if (typeof info === 'object') {
      Object.keys(info).forEach(key => {
        if (keyIn(user, key)) {
          user[key] = info[key] as never;
        }
      });
    }
  };

  const setIsLogin = (flag: boolean) => {
    isLogin.value = flag;
  };

  // 添加退出登录的方法
  const logout = () => {
    // 重置用户信息
    user.display_name = '';
    user.email = '';
    user.id = 0;
    user.org_id = 0;
    user.status = 'pending';
    user.user_type = '';
    // 重置登录状态
    isLogin.value = false;
  };

  return { user, isLogin, setUser, setIsLogin, logout };
});
