<template>
  <Layout>
    <section class="contact-container">
      <i class="contact-desc">{{ t('contact.contactDesc') }}</i>

      <div class="contact-box">
        <h3>{{ t('contact.contactEmail') }}</h3>
        <a-space :size="16">
          <span>{{ t('contact.emailClick') }}:</span>
          <a
            style="font-size: 16px"
            :href="`mailto:${emailInfo.email}?subject=${encodeURIComponent(
              emailInfo.subject
            )}&body=${encodeURIComponent(emailInfo.body)}`"
          >
            {{ emailInfo.email }}
          </a>
        </a-space>
      </div>

      <a-divider orientation="left">{{ t('loginModule.otherLogin') }}</a-divider>

      <div class="contact-box">
        <h3>{{ t('contact.contactInput') }}</h3>
        <a-textarea
          v-model:value="contact"
          :autosize="{ minRows: 5, maxRows: 5 }"
          :maxlength="500"
          showCount
          :placeholder="t('contact.inputSuggestion')"
        />
        <a-button
          :disabled="!contact"
          :loading="loading"
          type="primary"
          style="margin: 10px 0; width: 80px"
          @click="submitFeedback"
        >
          {{ t('global.okBtnText') }}
        </a-button>
      </div>
    </section>
  </Layout>
</template>

<script setup lang="tsx">
import i18n from '@/i18n';
import contactApi from '@/api/contact';
import Layout from '@/components/layout/Layout.vue';
import { message } from 'ant-design-vue';

const { t } = i18n.global;
const contact = ref('');
const loading = ref(false);
const emailInfo = ref({
  email: 'contact@noiz.ai',
  subject: 'Issue Feedback',
  body: 'You can mention the problems or suggestions here...'
});

const submitFeedback = () => {
  contactApi
    .submitFeedback({
      data: {
        content: contact.value
      }
    })
    .then(() => {
      contact.value = '';
      message.success(t('global.submitSuc'));
    });
};
</script>

<style scoped lang="less">
.contact-container {
  margin-top: 50px;
  width: 65%;
  min-width: 400px;

  .contact-desc {
    color: #6b6b6b;
  }

  .contact-box {
    margin: 30px auto;
    padding: 16px;
    background-color: #f8f8f8;
    border-radius: 5px;

    > h3 {
      margin-bottom: 30px;
    }

    :deep(.ant-input-textarea-show-count::after) {
      position: absolute;
      right: 5px;
      bottom: 5px;
    }
  }
}
</style>
